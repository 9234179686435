import $ from 'jquery';

const TOP = {
  ready: ()=>{
    TOP.initCarousel();
    TOP.initScroll();
  },
  initCarousel: ()=>{
    const change = ()=>{
      const now = $('#carousel > .item.active').index();
      const length = $('#carousel > .item').length;
      const target = (now+1 < length) ? now+1 : 0;

      $('#carousel > .item').removeClass('active').eq(target).addClass('active');
    };

    setInterval(change, 5000);
  },
  initScroll: ()=>{
    $(window).on('scroll', ()=>{
      const now = $(window).scrollTop();

      if (typeof now === 'number') {
        if (0 < now) {
          $('#header').addClass('active');
        } else {
          $('#header').removeClass('active');
        }
      }
    });
  }
};

$(()=>{
  switch ($('body').attr('id')) {
    case 'page-top':
      TOP.ready();
  }

  $('#menu').on('click', ()=>{
    $('#header').toggleClass('active');
  });

  let scrollY = 0;
  $(window).on('scroll', ()=>{
    const now = $(window).scrollTop();

    if (typeof now === 'number') {
      if (now < scrollY || now === 0) {
        $('#header > .site-title').removeClass('hidden');
      } else {
        $('#header > .site-title').addClass('hidden');
      }
  
      scrollY = now;
    }
  });
});